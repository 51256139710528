import { useId, useState } from 'react';

import cx from 'classnames';
import { motion } from 'framer-motion';

import { Column, Grid } from '~/ui/components/grid';
import { Icon } from '~/v1/components/icons/icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import Markdown from '~/v1/components/markdown/markdown';

import styles from './item.module.scss';
import { type AccordionTextType } from '../text.interface';

export const AccordionTextItem: React.FC<AccordionTextType> = ({ answer, question }) => {
  const ariaId = useId();

  const [isExpanded, setExpanded] = useState(false);

  const onActionClick = () => setExpanded(!isExpanded);

  return (
    <div className={styles.item}>
      <button
        className={styles.action}
        aria-label={question}
        aria-controls={ariaId}
        aria-expanded={isExpanded}
        onClick={onActionClick}
      >
        <Grid>
          <Column sm={4} md={6} lg={6}>
            <span className="bodyLargeTypography">{question}</span>
          </Column>
          <Column sm={1} md={1} lg={1} className={styles.iconWrapper}>
            <Icon className={styles.icon} type={isExpanded ? IconType.Minus : IconType.Plus} />
          </Column>
        </Grid>
      </button>

      <motion.div
        initial={{ overflow: 'hidden' }}
        animate={{ height: isExpanded ? 'auto' : 0 }}
        transition={{ duration: 0.2, delay: isExpanded ? 0 : 0.2 }}
        className={cx(styles.expand, {
          [styles.isExpanded]: isExpanded,
        })}
      >
        <motion.div
          id={ariaId}
          aria-labelledby={ariaId}
          initial={{ opacity: 0 }}
          transition={{ duration: 0.2, delay: isExpanded ? 0.2 : 0 }}
          animate={{ opacity: isExpanded ? 1 : 0 }}
        >
          <div inert={!isExpanded}>
            <Grid>
              <Column sm={4} md={6} lg={6}>
                <Markdown className="paragraphTypography">{answer}</Markdown>
              </Column>
            </Grid>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};
